import { ParamsType } from './index'

const denormalizer = ({ pageSize, pageNo, queryLiveTime, liveTypes }: ParamsType) => {
  return {
    queryLiveTime: queryLiveTime || new Date(),
    pageSize,
    pageNo,
    liveTypes,
  }
}

export default denormalizer
