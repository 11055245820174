import { useInfiniteQuery } from 'react-query'
import { useRouter } from 'next/router'

// Libs
import { fetchLiveList } from '@/api/fetchLiveList'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

// Types

interface ILiveTag {
  name: string
}

export type LiveListType = {
  kolName: string
  title: string
  coverPhotoUrl: string
  startTime: string
  liveCloseTime: string
  endTime: string
  liveType: number
  dummyViews: number
  roomId: string
  kolHeadshotUrl: string
  tags: ILiveTag[]
  liveSourceType?: number
  liveSource?: string
  liveTime: string
}

export type LiveListData = {
  roomList: LiveListType[]
  totalPage: {
    totalCount: number
    totalPage: number
  }
}

interface IParams {
  pageSize: number
  liveTypes?: number[]
}

const defaultLiveListParams: IParams = { pageSize: 20 }

export const useLiveList = ({ params = defaultLiveListParams, liveDataSSR }: { params?: IParams; liveDataSSR?: any }) => {
  const { pageSize, liveTypes = [] } = params
  const defaultPageNo = 1

  const router = useRouter()

  const liveListData = useInfiniteQuery(
    [QUERY_KEY.FETCH_LIVE_LIST, { pageSize, liveTypes }],
    ({ pageParam = defaultPageNo }) =>
      fetchLiveList({
        pageSize,
        pageNo: pageParam,
        ...(liveTypes.length > 0 && { liveTypes: liveTypes.join(',') }),
      }),
    {
      refetchInterval: 100 * 1000, // 目前每 100 秒重新 call API
      placeholderData: () => {
        return {
          pageParams: [undefined],
          pages: [liveDataSSR],
        }
      },
      staleTime: Infinity,
      cacheTime: 5 * 1000,
      getNextPageParam: (lastPage, pages) => {
        if (!router.isReady) return undefined

        const currentPage = pages.length
        const totalPage = lastPage?.totalPage.totalPages
        let result: number | undefined = 0

        if (lastPage?.totalPage.totalPages === 0) {
          result = undefined
        } else {
          result = currentPage === totalPage ? undefined : currentPage + 1
        }

        return result
      },
    },
  )

  return liveListData
}
