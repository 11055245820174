import { useState, useEffect, useRef } from 'react'
import classnames from 'classnames/bind'
import { format, differenceInSeconds } from 'date-fns'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'
import { useTranslation } from 'next-i18next'
import qs from 'qs'
import dynamic from 'next/dynamic'

// Libs
import { goToLiveRoom } from '@/methods/goToLiveRoom'
import { envConfigs } from '@/constants/config'
import handleImageBlur from '@/methods/handleImageBlur'
import { useLiveRoomData } from './hooks/useLiveRoomData'
import { handleGtmBookCalendarLive, handleGtmClickLiveRoom } from '@/methods/handleGtmEvent'

// Components
import Button from '@/components/common/Button'
import Image from '@/components/common/Image'
import Insight from '@/components/shared/Insight'
import { PlaceholderType } from '@/components/common/Image'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { ROOM_LIVE_TYPE, LIVE_SOURCE_TYPE } from '@/constants/liveRoom'
import { LIVE_TAG } from '@/constants/liveTag'
import { ImageSizes } from '@/constants/imageSizes'
import { SENTRY_TAGS } from '@/constants/sentry'
import { ENVIORNMENT } from '@/constants/config'
import { DEFAULT_LOCALE } from '@/constants/defaultLocale'
import { GtmClickLiveRoomLiveStatus, GtmClickLiveRoomPosition } from '@/constants/gtmEvent'

// Types
import { LiveListType } from '@/components/shared/LiveList/hooks/useLiveList'

// Styles
import styles from './style.module.scss'

const LiveItemPlayer = dynamic(() => import('./components/LiveItemPlayer'), {
  ssr: false,
})

const cx = classnames.bind(styles)

interface ILiveTag {
  name: string
}

type ListItemType = LiveListType & {
  className?: string
  index: number
  tags: ILiveTag[]
  gtmLocation?: string
}

const ListItem = (props: ListItemType) => {
  const {
    className,
    title,
    coverPhotoUrl,
    kolHeadshotUrl,
    kolName,
    startTime,
    liveCloseTime,
    dummyViews,
    roomId,
    liveType,
    endTime,
    tags,
    gtmLocation,
    liveSourceType,
    liveSource,
    liveTime,
  } = props

  const { t } = useTranslation()

  const [displayDateWithTime, setDisplayDateWithTime] = useState<string | null>(null)
  const [isShowLiveItemPlayer, setIsShowLiveItemPlayer] = useState(false)
  const [isLiveItemInersecting, setIsLiveItemInersecting] = useState(false)

  const videoRef = useRef(null)
  const liveItemRef: any = useRef(null)

  const router = useRouter()

  const isLive = liveType === ROOM_LIVE_TYPE.CURRENT
  const isFuture = liveType === ROOM_LIVE_TYPE.FUTURE
  const isPast = liveType === ROOM_LIVE_TYPE.PAST

  const isLivePage = router.pathname === ROUTE_PATH.LIVE

  const isOverCloseTime = differenceInSeconds(new Date(), new Date(liveCloseTime)) > 0

  const { roomLivingData, hasLiveUrl } = useLiveRoomData({ roomId, isLive: isLive && !isOverCloseTime, enabled: isLivePage }) // 這裡的 isLive 只單純判斷 liveType 會因為 server side cache 而資料有落差 // 所以多判斷 liveCloseTime
  const currentLocale = router.locale === 'default' ? DEFAULT_LOCALE : router.locale

  const isMP4 = liveSourceType === LIVE_SOURCE_TYPE.MP4

  useEffect(() => {
    // 解決clientSide ServerSide時間不同導致的hydration問題
    const date = format(new Date(startTime), 'MM-dd HH:mm')

    setDisplayDateWithTime(date)
  }, [])

  useEffect(() => {
    if (!isLive) return

    const options = {
      root: null,
    }

    // 目前每個 LiveItem 自己一個 IntersectionObserver
    // 如果同時直播的數量變多，要考慮一個 IntersectionObserver observe 所有 LiveItem
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsLiveItemInersecting(true)
      } else {
        setIsLiveItemInersecting(false)
      }
    }, options)

    intersectionObserver.observe(liveItemRef.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLive])

  const handleClick = () => {
    goToLiveRoom({ router, roomId, liveType, liveSourceType })
    const liveStatus = isLive ? GtmClickLiveRoomLiveStatus.LIVE : isFuture ? GtmClickLiveRoomLiveStatus.PREVIEW : GtmClickLiveRoomLiveStatus.REVIEW
    const position = GtmClickLiveRoomPosition.ROOM_CARD
    handleGtmClickLiveRoom({ position, roomId, liveStatus })
  }

  const handleReserveClick = (event: any) => {
    event.stopPropagation()

    // 拿到完整 url
    const liveUrl = `${envConfigs.domainUrl}${ROUTE_PATH.LIVE}/${roomId}`
    const analysisUrl = `${envConfigs.domainUrl}/${currentLocale}/${ROUTE_PATH.ANALYSIS}`
    const analysisInfoQs = { [SENTRY_TAGS.RESERVATION_LIVE_ROOM]: liveUrl }
    // FIXME: 追蹤數據用途，使用完要移除
    const analysisUrlWithQs = `${analysisUrl}?${qs.stringify(analysisInfoQs)}`

    const formatTime = (time: string) => {
      // original startTime 2021-08-31T10:00:00+08:00
      // google calendar 需要的格式 20210831T100000

      return time.replace(/-/g, '').replace(/:/g, '').replace('+0800', '')
    }

    handleGtmBookCalendarLive({ location: gtmLocation as string, roomId })
    // 定義 google calandar 的參數
    const calandarConfig = {
      // 把+8:00去掉
      startTime: formatTime(startTime),
      endTime: formatTime(endTime),
      title: t('Live_Calandar_Title', { kolName, title }),
      description: t('Live_Calandar_Description', {
        displayDateWithTime,
        // FIXME: 追蹤數據用途，使用完要移除
        liveUrl:
          process.env.DEPLOY_ENV === ENVIORNMENT.STAGE || process.env.DEPLOY_ENV === ENVIORNMENT.PRE || process.env.DEPLOY_ENV === ENVIORNMENT.PROD
            ? analysisUrlWithQs
            : liveUrl,
      }),
    }
    window.open(
      `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${calandarConfig.startTime}/${calandarConfig.endTime}&details=${calandarConfig.description}&text=${calandarConfig.title}`,
    )
  }

  const hasSuperStarTag = () => {
    let result = false

    tags.forEach((tag: any) => {
      if (LIVE_TAG.SUPER_STAR_KOL.includes(tag.name)) {
        result = true
      }
    })

    return result
  }

  return (
    <div onClick={handleClick} className={cx('item', className)} ref={liveItemRef}>
      <a href={`${ROUTE_PATH.LIVE}/${roomId}`} />
      <div className={cx('item-image')}>
        <Image
          src={coverPhotoUrl}
          width={224}
          height={300}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          alt={title}
          sizes='(max-width: 710px) 60vw, (max-width: 1200px) 50vw, 33vw'
          placeholder={PlaceholderType.blur}
          priority
          blurDataURL={handleImageBlur()}
        />

        {!isEmpty(tags) && hasSuperStarTag() && (
          <div className={cx('item__super-star-tag')}>
            <Image src='/images/svg/super-star.svg' fill alt='super star' />
          </div>
        )}

        {isLive && hasLiveUrl && isLiveItemInersecting && isLivePage && (
          <LiveItemPlayer
            className={cx('item-player', { isShow: isShowLiveItemPlayer })}
            wrapperClassName={cx('item-player-wrapper')}
            videoRef={videoRef}
            src={isMP4 ? liveSource : roomLivingData?.liveUrlInfo[0]?.streamUrl}
            sessionRoomId={roomLivingData?.gdata.sessionRoomId}
            isShowLiveItemPlayer={isShowLiveItemPlayer}
            setIsShowLiveItemPlayer={setIsShowLiveItemPlayer}
            liveTime={liveTime}
          />
        )}
      </div>

      <div className={cx('item-content')}>
        <div className={cx('item-logo')}>
          <Image
            src={kolHeadshotUrl}
            width={50}
            height={50}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
            alt={kolName}
            sizes={ImageSizes.headShot}
          />
        </div>
        <div className={cx('item-content__header')}>
          <p className={cx('item-content-name')}>{kolName}</p>
          <h3 className={cx('item-content-title')}>{title}</h3>
        </div>

        <div className={cx('item-content__footer')}>
          {!isFuture && <p className={cx('item-content-time')}>{displayDateWithTime}</p>}

          {isFuture && (
            <Button className={cx('item-content__booking')} onClick={handleReserveClick} throttleDuration={0} size='sm'>
              {t('Live_Reserve_Button')}
            </Button>
          )}
        </div>
      </div>

      {isLive && <Insight status='view' count={dummyViews} theme='transparent-black' position='absolute' top='10px' left='10px' zIndex='11' />}

      {isPast && (
        <div className={cx('item-tag')}>
          <p className={cx('item-tag-status', 'past')}>{t('Live_Past')}</p>
          <p className={cx('item-tag-views')}>{t('Common_Views', { views: dummyViews })}</p>
        </div>
      )}

      {isFuture && (
        <div className={cx('item-tag')}>
          <p className={cx('item-tag-status', 'future')}>{t('Live_Future')}</p>
          <p className={cx('item-tag-views')}>{displayDateWithTime}</p>
        </div>
      )}
    </div>
  )
}

export default ListItem
