import classnames from 'classnames/bind'
// Libs
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

// Constants
// Components
import 'react-loading-skeleton/dist/skeleton.css'

// Constants

// Types

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

type ListItemType = {
  className?: string
  count?: number
}

const ListItemSkeleton = (props: ListItemType) => {
  const { className, count = 1 } = props

  return (
    <>
      {Array.from(Array(count)).map((_, index) => (
        <SkeletonTheme key={index} baseColor='#EAEAEA' highlightColor='#F5F5F5'>
          <div className={cx('item', className)}>
            <div className={cx('item-image')}>
              <Skeleton height={'100%'} />
            </div>

            <div className={cx('item-content')}>
              <Skeleton circle containerClassName={cx('item-logo')} />
              <div className={cx('item-content__header')}>
                <Skeleton borderRadius={100} containerClassName={cx('item-content-name')} />
                <Skeleton borderRadius={100} containerClassName={cx('item-content-name')} />
              </div>

              <div>
                <Skeleton borderRadius={100} height={16} />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </>
  )
}

export default ListItemSkeleton
