import React, { useRef } from 'react'
import classnames from 'classnames/bind'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'next-i18next'

// Libs

// Components
import LiveItem from '@/components/shared/LiveItem'
import Popup from '@/components/common/Popup'
import ListItemSkeleton from '@/components/shared/LiveItem/skeleton/LiveItemSkeleton'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const LiveList = (props: any) => {
  const { liveListData, gtmLocation } = props

  const { t } = useTranslation()

  const noKolLivePopopRef = useRef<any>(null)

  const handleNextpage = () => {
    if (!liveListData.isFetchingNextPage) {
      liveListData.fetchNextPage()
    }
  }

  return (
    <div className={cx('live__list')}>
      <InfiniteScroll className={cx('live__list-wrapper')} loadMore={handleNextpage} hasMore={liveListData.hasNextPage}>
        {liveListData?.isSuccess &&
          liveListData?.data?.pages?.length > 0 &&
          liveListData?.data?.pages.map((item: any, pageIndex: number) => (
            <React.Fragment key={pageIndex}>
              {item?.roomList?.map((item: any, index: number) => (
                <LiveItem
                  className={cx('live__list-wrapper-item')}
                  key={`${item.roomId}-${index}`}
                  index={index}
                  noKolLivePopopRef={noKolLivePopopRef}
                  gtmLocation={gtmLocation}
                  {...item}
                />
              ))}
            </React.Fragment>
          ))}
        {liveListData?.isFetching && <ListItemSkeleton count={10} />}
      </InfiniteScroll>

      <Popup
        title={t('Live_Not_Start')}
        ref={noKolLivePopopRef}
        onConfirm={() => noKolLivePopopRef.current.setIsPopupOpen(false)}
        hasCancel={false}
      />
    </div>
  )
}

export default LiveList
