import Service, { getCallApiFunction } from '../service'
import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export type ParamsType = {
  queryLiveTime?: Date
  pageSize: number
  pageNo: number
  liveTypes?: any
}

class FetchLiveListApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_LIVE_LIST'

    this.config = {
      url: `/v1/room/list`,
      method: 'GET',
      params,
    }

    this.denormalizer = denormalizer
  }
}

export const fetchLiveList = (params: ParamsType) => getCallApiFunction(new FetchLiveListApi(params))
