import { useRouter } from 'next/router'
import { useQuery } from 'react-query'

// Libs
import { fetchRoomLivingData } from '@/api/fetchRoomLivingData'
import { isMacOrIOS } from '@/utils/navigator'

// Constants
import { STREAM_MODE } from '@/constants/socketEvent'
import { QUERY_KEY } from '@/constants/queryKey'

// Types

export const useLiveRoomData = ({ roomId, isLive, enabled = true }: { roomId: string; isLive: boolean; enabled?: boolean }) => {
  const router = useRouter()

  // 房間 socket 或串流資訊
  const { data: roomLivingData = { liveUrlInfo: [] } } = useQuery(
    [QUERY_KEY.FETCH_ROOM_LIVING_DATA, { sessionSn: roomId }],
    () => fetchRoomLivingData({ sessionSn: roomId, mode: isMacOrIOS ? STREAM_MODE.HLS : STREAM_MODE.FLV }),
    {
      enabled: router.isReady && isLive && enabled,
    },
  )

  return { roomLivingData, hasLiveUrl: roomLivingData?.liveUrlInfo[0]?.streamUrl }
}
